import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  Row,
  Spinner
} from "reactstrap";
import confirm from "reactstrap-confirm";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { updateIdea } from "../../../store/slices/idea/ideaActions";
import Approved from "./Status/Approved";
import Evaluated from "./Status/Evaluated";
import Implemented from "./Status/Implemented";
import Replicated from "./Status/Replicated";
import Rewarded from "./Status/Rewarded";
import { setIsLoading } from "../../../store/slices/shared/sharedSlice";
import Modal from "../components/Modal/Modal";

const EditIdea = () => {
  const isLoading = useSelector((state) => state.shared.isLoading);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = useParams().id;
  const { status, PQCDSM, ideaDesc } = location.state;
  const [changedData, setChangedData] = useState({});

  const editStatus = async () => {
    try {
      let result = await confirm({
        title: <>Warning</>,
        message: t("Are you sure you want to submit this?"),
        confirmText: "Confirm",
        confirmColor: "primary",
        cancelColor: "link text-danger",
      });
      if (result) {
        dispatch(setIsLoading(true))
        dispatch(updateIdea({ idea: changedData, dispatch, path: '/admin/idea', history, setIsLoading }));

      }
    } catch (e) {
      return e;
    }
  };

  return (
    <>
      <SimpleHeader name="Edit Idea" parentName="Idea management" />
      <Modal visible={isLoading} >
        <Spinner
          color="light"
          style={{
            height: "5rem",
            width: "5rem",
          }}
        >
          Loading...
        </Spinner>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader className="position-sticky top-0" style={{ zIndex: 1 }}>
                  <h3 className="mb-0">Edit</h3>
                  <h3 className="mt-2">{t('form.ideaDesc')}</h3>
                  <h4 className="ml-2">{ideaDesc}</h4>
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <div className="form-row">
                      {Object.entries(status).map((el) => {
                        switch (el[0]) {
                          case "evaluated":
                            if (!el[1].value) {
                              return (
                                <Evaluated
                                  data={el}
                                  setChangedData={setChangedData}
                                  id={id}
                                />
                              );
                            } else {
                              return (
                                <Evaluated
                                  data={el}
                                  setChangedData={setChangedData}
                                  id={id}
                                  view
                                />
                              );
                            }
                          case "approved": {
                            if ((/true/i).test(status.evaluated?.accepted)) {
                              if (!el[1].value) {
                                return (
                                  <Approved
                                    data={el}
                                    setChangedData={setChangedData}
                                    id={id}
                                  />
                                );
                              } else {
                                return (
                                  <Approved
                                    data={el}
                                    setChangedData={setChangedData}
                                    id={id}
                                    view
                                  />
                                );
                              }
                            }
                          }
                          case "implemented":
                            if ((/true/i).test(status.approved?.accepted)) {
                              if (!el[1].value) {
                                return (
                                  <Implemented
                                    data={el}
                                    setChangedData={setChangedData}
                                    id={id}
                                    PQCDSM={PQCDSM}
                                  />
                                );
                              } else {
                                return (
                                  <Implemented
                                    data={el}
                                    setChangedData={setChangedData}
                                    id={id}
                                    PQCDSM={PQCDSM}
                                    view
                                  />
                                );
                              }
                            }
                          case "rewarded":
                            if ((/true/i).test(status.implemented?.accepted)) {
                              if (!el[1].value) {
                                return (
                                  <Rewarded
                                    data={el}
                                    setChangedData={setChangedData}
                                    id={id}
                                  />
                                );
                              } else {
                                return (
                                  <Rewarded
                                    data={el}
                                    setChangedData={setChangedData}
                                    id={id}
                                    view
                                  />
                                );
                              }
                            }
                        }
                      })}
                    </div>
                    {(/true/i).test(status.implemented?.accepted) && <Replicated data={status.replicated?.type}
                      setChangedData={setChangedData}
                      id={id} />}
                    <Button
                      color="primary"
                      onClick={editStatus}
                      disabled={!Object.keys(changedData).length}
                    >
                      {t('submit')}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditIdea;