import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { Button, Card, CardHeader, Col, Input, Row } from "reactstrap";
import { notify, validateAndAddError } from "../../../../utils/helpers";
import ErrorMessage from "../../../../components/ErrorMessage/ErrorMessage";

function Rewarded({ data, view, setChangedData, id }) {
    const notificationAlertRef = useRef(null);
    const { t, i18n } = useTranslation();
    const [isRewarded, setIsRewarded] = useState(false)
    const [dataChanged, setDataChanged] = useState(false);
    const [savings, setSavings] = useState(0);
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState(new Date().toISOString().split('T')[0]);
    const [validationErrors, setValidationErrors] = useState({});

    const saveChangesHandler = () => {
        let errors = {};
        // Check for validation errors
        validateAndAddError(errors, 'eventName', eventName, 'text', i18n.language);

        setValidationErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }
        notify(
            "success",
            'Your changes have been saved.',
            notificationAlertRef,
            'Saved Successfully'
        );
        const rewardedChanges = {
            eventName,
            eventDate,
            savings,
            accepted: isRewarded,
            value: true,
            date: new Date(),
        }
        setChangedData({
            data: {
                rewarded:
                    rewardedChanges,
            },
            key: 'rewarded',
            id: id
        });
    }
    return (
        <Card className="w-100 p-3" >
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <CardHeader>
                <p className="h3 text-capitalize">
                    {t('editIdea.Rewarded')}
                </p>
            </CardHeader>
            <Row className="w-100">
                <Col className="m-5" md="4">
                    <p className="h3">{t('editIdea.Status')}</p>
                    <label
                        className="custom-toggle custom-toggle-default mr-1"
                        id="idea-reward-radio"
                    >
                        <input
                            checked={view ? data[1].value : isRewarded}
                            onChange={() => {
                                setIsRewarded((prevState) => {
                                    return !prevState
                                });
                                setDataChanged(true)
                            }}
                            type="checkbox"
                        />
                        <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                        />
                    </label>
                </Col>
                {/* Rewarded */}
                {(data[1].value || isRewarded) && (
                    <>
                        <Col className="m-5" md="4">
                            <p className="h3">{t('editIdea.Reward')}</p>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="Reward"
                                value={view ? data[1]?.savings : savings}
                                disabled={view}
                                min={0}
                                onChange={(e) => setSavings(+e.target.value)}
                                required
                            />
                        </Col>
                        {/* event name */}
                        <Col className="m-5" md="4">
                            <p className="h3">{t('editIdea.eventName')}</p>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={t('editIdea.eventName')}
                                value={view ? data[1]?.eventName : eventName}
                                disabled={view}
                                min={0}
                                onChange={(e) => setEventName(e.target.value)}
                                required
                            />
                            <ErrorMessage errors={validationErrors} property='eventName' />
                        </Col>
                        {/* event date */}
                        <Col className="m-5" md="4">
                            <p className="h3">{t('editIdea.eventDate')}</p>
                            <Input
                                disabled={view}
                                value={view ? data[1].eventDate : eventDate}
                                type="date"
                                onChange={(e) => setEventDate(e.target.value)}
                                required
                            />
                        </Col>
                    </>)}
            </Row>
            <div className="text-center">
                {!view && <Button className="my-4" color="primary" type="button" onClick={saveChangesHandler} disabled={!dataChanged || view} >
                    {t('evaluated.saveChanges')}
                </Button>}
            </div>
        </Card>
    )
}

export default Rewarded